<template>
  <div class="banner-container">
    <div class="icon-container">
      <span v-for="user in iconList" :key="user.id" class="icon">
        <img :src="require(`@/assets/images/icons/${user.icon}.svg`)" alt="icon" width="50px" height="50px" />
      </span>
    </div>
    <div class="copy-container">
      <div class="title">{{ bannerTitle }}</div>
      <div class="description">We've found you some funding matches! Our dedicated experts will be in touch to help secure your funding.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchingPageBanner',
  data() {
    return {
      iconList: [
        { id: 1, icon: 'brokerImage1' },
        { id: 2, icon: 'brokerImage2' },
        { id: 3, icon: 'brokerImage3' },
        { id: 4, icon: 'brokerImage4' },
        { id: 5, icon: 'brokerImage5' }
      ]
    }
  },
  computed: {
    bannerTitle() {
      return window.WHITE_LABEL_STYLES?.subdomain !== 'swoop' ? 'A relationship manager will be in touch!' : 'A Swoop relationship manager will be in touch!'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.banner-container {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  background: linear-gradient(48.85deg, #00999e -32.72%, #243039 100%);
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: $breakpoint-sm-max) {
    height: auto;
    flex-direction: column;
  }
  &:before {
    content: '';
    right: -300px;
    position: absolute;
    height: 500px;
    width: 100%;
    background: #96daea;
    transform: rotate(140deg);
    opacity: 0.1;
    @media only screen and (max-width: $breakpoint-sm-max) {
      right: -300px;
      height: 300px;
      width: 200%;
    }
  }
}
.icon-container {
  transform-style: preserve-3d;
  min-width: 100px;
  @media only screen and (max-width: $breakpoint-sm-max) {
    height: auto;
  }
  .icon {
    display: inline-block;
    overflow: hidden;
    width: 85px;
    &:not(:first-child) {
      margin-left: -60px;
    }
    img {
      display: block;
    }
  }
}
.copy-container {
  color: var(--color-primary-50);
  width: 70%;
  .title {
    font-size: 23px;
    letter-spacing: -0.4%;
    font-weight: bold;
    margin-bottom: 10px;
    @media only screen and (max-width: $breakpoint-sm-max) {
      font-size: 16px;
    }
  }
  .description {
    font-size: 16px;
    letter-spacing: -0.4%;
    @media only screen and (max-width: $breakpoint-sm-max) {
      font-size: 14px;
    }
  }
}
</style>
