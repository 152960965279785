<template>
  <div class="page-container">
    <div class="ui active inverted dimmer page-loader" v-if="isLoading">
      <div class="ui medium text loader">
        <spinner :size="64" :loader="true" color />
      </div>
    </div>
    <div class="page-title">
      <h1>Your matches</h1>
      <p>Check out your current options for loans and grants.</p>
    </div>

    <IntegrationsBanner />

    <div v-if="fundingAmountValue >= 10000" class="banner">
      <MatchingPageBanner />
    </div>

    <div class="funding-container">
      <div class="header">
        <h1>Loan funding</h1>
        <button id="see-all-loans" class="btn-see-products" @click="goToLoans()">See all loans</button>
      </div>

      <ProductCarousel :products="loanJourneyProducts" :emptyMessage="emptyLoansMessage" :formName="lastLoanJourney" @refetch-products="fetchProducts" />
    </div>

    <div class="funding-container">
      <div class="header">
        <h1>Grant funding</h1>
        <button id="see-all-grants" class="btn-see-products" @click="goToGrants()">See all grants</button>
      </div>

      <ProductCarousel :products="grantJourneyProducts" :emptyMessage="emptyGrantsMessage" :formName="findGrantFormName" />
    </div>

    <div class="funding-container">
      <div class="header">
        <h1>Equity funding</h1>
      </div>

      <EquityBanner />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { authGetters, userRoutine } from '@/store/modules/auth/routines'
import { companyGetters } from '@/store/modules/company/routines'
import { fundingAmountGetters, fundingAmountRoutine, currentFormNameRoutine } from '@/store/modules/currentFormDetails/routines'
import { fundingGetters } from '@/store/routines'
import illionAllowedTenants from '@/mixins/illionAllowedTenants'
import deepLinking from '@/mixins/deepLinking'
import convertToCamelCase from '@product/features/products/mixins/convertToCamelCase'
import MatchingPageBanner from '@/components/MatchingPageBanner'
import { getAllForms, getFormStructure } from '@/api/goals/index'
import { sortLoanFormsByMostRecentFilled } from '@/utils/sorting'
import { getRecommendationsList } from '@product/api/opportunity'
import { opportunitySpeedDic, amplitudeEvents } from '@/utils/constants'
import IntegrationsBanner from '@product/features/products/matches/integrationsBanner'
import ProductCarousel from '@product/features/products/matches/productCarousel'
import EquityBanner from '@product/features/products/matches/equityBanner'
import Spinner from '@/components/Spinner'
import { onboardingGetters } from '@/store/modules/onboarding/routines'

export default {
  name: 'Matches',
  components: {
    MatchingPageBanner,
    IntegrationsBanner,
    ProductCarousel,
    Spinner,
    EquityBanner
  },
  mixins: [illionAllowedTenants, deepLinking, convertToCamelCase],
  data() {
    return {
      lastLoanJourney: null,
      loanForms: [],
      lastFormDetails: null,
      loanJourneyProducts: [],
      grantJourneyProducts: [],
      opportunitySpeedDic,
      isLoading: false,
      findGrantFormName: 'findGrant'
    }
  },
  async created() {
    await this.requestUser()
    try {
      this.isLoading = true
      await this.getLastFilledLoanForm()
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      setFundingAmountFieldValue: fundingAmountRoutine.TRIGGER,
      requestUser: userRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER
    }),
    async getLastFilledLoanForm() {
      const { data } = await getAllForms()

      if (data) {
        this.loanForms = data?.filter(loan => loan.type === 'Loan' && loan.name !== 'loanJourney') // The old registrations were migrated to this LoanJourney, so we don't want to display.

        if (!this.filledForms || this.filledForms.length === 0) {
          // this means the user hasn't gone through any loan journey (eg: selected find grant)
          // per discussion on PRD-693, we will show the user Business Expansion as default
          this.lastFilledFormDetails = this.loanForms.find(loan => loan.name === 'businessExpansion')
        } else {
          this.lastFilledFormDetails = this.sortedLoanForms[0]
        }

        this.lastLoanJourney = this.lastFilledFormDetails?.name
        this.setCurrentFormName(this.lastLoanJourney)
        await Promise.all([this.populateLoanProducts(), this.populateGrantProducts()])

        await this.getFundingAmount(this.lastFilledFormDetails?.formId)
      }
    },
    async populateLoanProducts() {
      const { data } = await getRecommendationsList(this.companyId, this.lastLoanJourney)

      this.loanJourneyProducts = data?.opportunities
    },
    async populateGrantProducts() {
      const { data } = await getRecommendationsList(this.companyId, 'findGrant')

      this.grantJourneyProducts = data?.opportunities
    },
    goToLoans() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.Matches.Overview.SEE_ALL_LOANS
      })

      this.$router.push({ name: 'loan-matches' })
    },
    goToGrants() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.Matches.Overview.SEE_ALL_GRANTS
      })

      this.$router.push({ name: 'grant-matches' })
    },
    async getFundingAmount(formId) {
      if (!formId) return
      const res = await getFormStructure(this.companyId, formId)
      const fundingAmountFields = ['fundingAmount', 'borrowAmount'] // each form has different value we need to check them
      const getFundingAmountField = res?.data?.formSections?.map(section => section?.formFields.find(field => fundingAmountFields?.includes(field.name))).filter(field => field)
      this.fundingAmount = Number(getFundingAmountField?.[0].formFieldValue?.value)
      this.setFundingAmountFieldValue(this.fundingAmount)
    },
    async fetchProducts() {
      this.isLoading = true
      await Promise.all([this.populateLoanProducts(), this.populateGrantProducts()])
      this.isLoading = false
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID,
      fundingAmountValue: fundingAmountGetters.FUNDING_AMOUNT,
      getStartedFormData: onboardingGetters.GET_STARTED_FORM_DATA
    }),
    tenant() {
      return this.$config.whitelabel.country.defaultCountry || 'United Kingdom'
    },
    filledForms() {
      return this.user?.goalsCompleted?.filter(form => form.type === 'Loan')
    },
    sortedLoanForms() {
      return sortLoanFormsByMostRecentFilled(this.filledForms, this.loanForms)
    },
    emptyLoansMessage() {
      return "Sorry, there aren't any loan matches based on the information you've given us."
    },
    emptyGrantsMessage() {
      return "Sorry, there aren't any grant matches based on the information you've given us."
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.page-container {
  padding: 0 30px 30px 30px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.page-title h1 {
  margin: 0;
}

.page-title p {
  margin: 0;
}

.mobile-only {
  @media only screen and (min-width: $breakpoint-sm) {
    display: none;
  }
}

.desktop-only {
  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}

.banner {
  margin-top: 24px;

  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}

.btn-see-products {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  height: 24px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #003e52;
}

.btn-see-products:hover {
  background-color: var(--color-primary-400);
  color: #ffffff;
}

.funding-container {
  margin-top: 32px;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-top: 10px;
  }

  h1 {
    color: var(--color-primary-500);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;

    &:before {
      content: '';
      border-left: 3px solid var(--color-secondary-500);
      margin-right: 8px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
