<template>
  <div class="product-tile">
    <div class="header">
      <div class="title">
        <h1>{{ product.productName }}</h1>
        <div class="type" v-if="isLoan(product)">{{ product.subcategoryName }}</div>
      </div>
      <div class="icon">
        <img :src="productTileImage(product)" alt="Provider logo image" />
      </div>
    </div>

    <div class="product-content" v-if="isLoan(product)">
      <div class="line">
        <span class="label">Rates from</span>
        <span class="value" v-if="product.aprMin">{{ product.aprMin | numberSeparator }}%</span>
        <span class="value" v-else>-</span>
      </div>
      <div class="line">
        <span class="label">Decision time</span>
        <span class="value" v-if="product.speed">{{ opportunitySpeedDic[product.speed] }}</span>
        <span class="value" v-else>-</span>
      </div>
      <div class="line">
        <span class="label">Amount</span>
        <span class="value" v-if="product.minSize > 1 && product.maxSize">{{ product.minSize | commarize | currency }} - {{ product.maxSize | commarize | currency }}</span>
        <span class="value" v-else-if="product.maxSize">{{ product.maxSize | commarize | currency }}</span>
      </div>
    </div>

    <div class="product-content" v-if="product.type === 'Grant'">
      <div class="line">
        <span class="label">Closing date</span>
        <span class="value" v-if="product.closingDate">{{ product.closingDate | dateFormat }}</span>
        <span class="value" v-else>-</span>
      </div>
      <div class="line">
        <span class="label">Amount</span>
        <span class="value" v-if="product.minSize > 1 && product.maxSize">{{ product.minSize | commarize | currency }}-{{ product.maxSize | commarize | currency }}</span>
        <span class="value" v-else-if="product.maxSize">{{ product.maxSize | commarize | currency }}</span>
      </div>
    </div>

    <div class="actions">
      <button class="btn btn-primary btn-sm" @click="viewProduct(product)">View details</button>
    </div>
  </div>
</template>

<script>
import { opportunitySpeedDic, amplitudeEvents } from '@/utils/constants'

export default {
  name: 'ProductTile',
  props: {
    product: {
      type: Object,
      required: true
    },
    formName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      opportunitySpeedDic
    }
  },
  methods: {
    productTileImage(product) {
      if (product.logo) {
        return product.logo
      } else if (product.logoUri) {
        return product.logoUri
      } else if (product.providerName) {
        try {
          return require(`../../../assets/images/partners/${this.product.providerName}-logo.svg`)
        } catch {
          return require('@/assets/images/logo-not-found.svg')
        }
      } else {
        return require('@/assets/images/logo-not-found.svg')
      }
    },
    viewProduct(product) {
      if (this.formName === 'findGrant') {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.Matches.Overview.GRANT_PRODUCT_VIEW_DETAILS
        })
      } else {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.Matches.Overview.LOAN_PRODUCT_VIEW_DETAILS
        })
      }

      this.$router.push({ name: 'funding-explore-deal', params: { opportunityId: product.opportunityId } })
    },
    isLoan(product) {
      return product.type === 'Loan' || product.type === 'LiveOffer'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.product-tile {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 32px;
  flex: 0 0 31.433333%;

  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  border-radius: 16px;
  margin: 1px;

  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
    padding: 24px;
  }

  .header {
    display: flex;
    justify-content: space-evenly;
    height: 5vw;
    width: 100%;

    @media only screen and (max-width: $breakpoint-sm) {
      height: auto;
    }

    .title {
      display: flex;
      flex-direction: column;
      flex: 1;

      h1 {
        margin: 0;
        white-space: break-spaces;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.004em;

        &:before {
          content: '';
          border-left: none;
          margin-right: 0;
        }
      }
    }

    .type {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 5px 8px;
      border: 1px solid #f8783d;
      border-radius: 100px;
      margin-top: 8px;

      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: -0.004em;
      text-transform: uppercase;
      color: #f8783d;
    }

    .icon {
      align-self: flex-start;
      width: 64px;
      height: 64px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .product-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    width: 100%;
    margin-top: 32px;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 18px;
    }

    .line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding-bottom: 2px;

      &:not(:last-child) {
        border-bottom: 1px solid #dee8ec;
      }

      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.004em;
        color: #668b97;
      }

      .value {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        letter-spacing: -0.004em;
        color: #003e52;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 8px;

    @media only screen and (max-width: $breakpoint-sm) {
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
