<template>
  <div class="tiles" v-if="products && products.length > 0">
    <em class="material-icons arrow prev" :class="{ disabled: leftArrowDisabled, hide: leftArrowHide }" @click="scroll('left')"> keyboard_arrow_left_icon</em>
    <div class="scrollable" ref="scrollable" v-on:scroll="handleScroll">
      <ProductTile v-for="product in products" :key="product.opportunityId" :product="product" :formName="formName" />
    </div>
    <em class="material-icons arrow next" :class="{ disabled: rightArrowDisabled, hide: rightArrowHide }" @click="scroll('right')"> keyboard_arrow_right_icon</em>
  </div>
  <div class="empty" v-else>
    <FormOverlay v-if="isPageOverlayOpen">
      <EnquiryForm @refetch-products="refetchProducts" />
    </FormOverlay>
    <div class="icon"><searchCloseIcon /></div>
    <div class="content">{{ emptyMessage }}</div>
    <div class="actions">
      <button class="btn btn-primary btn-xs" @click="openOverlayForm()">Go back and adjust your search</button>
    </div>
  </div>
</template>

<script>
import ProductTile from '@product/features/products/matches/productTile'
import searchCloseIcon from '@/assets/images/icons/icons_search_close.svg?inline'
import { currentFormNameGetters, currentFormNameRoutine } from '@/store/modules/currentFormDetails/routines'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'
import { mapGetters, mapActions } from 'vuex'
import EnquiryForm from '../../goals/components/EnquiryForm'
import FormOverlay from '@/components/FormOverlay.vue'

export default {
  name: 'ProductCarousel',
  components: {
    ProductTile,
    searchCloseIcon,
    EnquiryForm,
    FormOverlay
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    emptyMessage: {
      type: String,
      required: true
    },
    formName: {
      type: String
    }
  },
  data() {
    return {
      leftArrowDisabled: true,
      leftArrowActive: false,
      rightArrowDisabled: false,
      rightArrowActive: false,
      scrollMonitor: null,
      rightArrowHide: false,
      leftArrowHide: false
    }
  },
  beforeDestroy() {
    this.scrollMonitor.disconnect()
  },
  mounted() {
    this.$nextTick(() => {
      this.monitorScroll()
    })
  },
  methods: {
    ...mapActions({
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER
    }),
    monitorScroll() {
      this.scrollMonitor = new MutationObserver(this.checkScroll)
      this.scrollMonitor.observe(this.$el, {
        attributes: true,
        childList: true,
        subtree: true
      })
    },
    checkScroll() {
      if (!this.$refs.scrollable?.clientWidth || !this.$refs.scrollable?.scrollWidth) return

      const overflown = this.$refs.scrollable.clientWidth !== this.$refs.scrollable.scrollWidth

      if (!overflown) {
        this.rightArrowHide = true
        this.leftArrowHide = true
      }
    },
    scroll(direction) {
      this.arrowClickEffectHandler(direction)
      const scrollAmount = 400
      const scroll = this.$refs.scrollable
      const position = this.$refs.scrollable.scrollLeft

      if (direction === 'left') {
        scroll.scrollTo({
          left: position - scrollAmount,
          behavior: 'smooth'
        })
      } else {
        scroll.scrollTo({
          left: position + scrollAmount,
          behavior: 'smooth'
        })
      }
    },
    arrowClickEffectHandler(direction) {
      if (direction === 'left' && !this.leftArrowDisabled) {
        this.leftArrowActive = true
        setTimeout(() => {
          this.leftArrowActive = false
        }, 300)
      }
      if (direction === 'right' && !this.rightArrowDisabled) {
        this.rightArrowActive = true
        setTimeout(() => {
          this.rightArrowActive = false
        }, 300)
      }
    },
    handleScroll() {
      const offset = 2
      const element = this.$refs.scrollable
      const scrollPosition = element.scrollLeft + offset
      const scrollAvailable = element.scrollWidth - element.clientWidth

      if (scrollAvailable <= scrollPosition) {
        if (!this.rightArrowDisabled) {
          this.rightArrowDisabled = true
        }
      }
      if (scrollAvailable > scrollPosition) {
        if (this.rightArrowDisabled) {
          this.rightArrowDisabled = false
        }
      }
      if (scrollPosition <= offset) {
        if (!this.leftArrowDisabled) {
          this.leftArrowDisabled = true
        }
      }
      if (scrollPosition > offset) {
        if (this.leftArrowDisabled) {
          this.leftArrowDisabled = false
        }
      }
    },
    openOverlayForm() {
      this.setCurrentFormName(this.formName)
      this.setPageOverlay(!this.isPageOverlayOpen)
    },
    refetchProducts(value) {
      this.setCurrentFormName(this.formName)
      this.$emit('refetch-products', value)
    }
  },
  computed: {
    ...mapGetters({
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
.arrow {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  z-index: 1;
  user-select: none;

  @media only screen and (max-width: $breakpoint-sm) {
    visibility: hidden;
  }
}

.arrow.disabled {
  color: var(--color-primary-100);
}
.hide {
  visibility: hidden;
}

.next {
  margin-left: -24px;
}

.funding-container {
  margin-top: 32px;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-top: 10px;
  }

  h1 {
    color: var(--color-primary-500);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;

    &:before {
      content: '';
      border-left: 3px solid var(--color-secondary-500);
      margin-right: 8px;
    }
  }

  .tiles {
    display: grid;
    grid-template-columns: 4px 1fr 4px;
    overflow: hidden;
    margin-top: 24px;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 18px;
    }
  }

  .scrollable {
    display: inline-grid;
    grid-auto-columns: calc(100% / 3 - 20px);
    grid-auto-flow: column;
    gap: 24px;
    overflow-x: scroll;
    scroll-behavior: smooth;

    @media only screen and (max-width: $breakpoint-sm) {
      grid-auto-flow: row;
      grid-auto-columns: unset;
      width: 100%;
      margin-right: 2px;
      gap: 8px;
    }
  }

  .scrollable::-webkit-scrollbar {
    display: none;
  }
}

.empty {
  margin-top: 16px;
  display: flex;
  padding: 24px;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  border-radius: 16px;

  @media only screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
  }

  .icon {
    width: 48px;
    height: 48px;
  }

  .content {
    flex-grow: 2;
  }
}
</style>