<template>
  <div class="equity-banner-locked" v-if="!equityUnlocked">
    <div class="content">
      <div class="icon-box">
        <equityIcon class="icon" />
      </div>

      <div class="text">
        <div class="title">Unlock equity</div>
        <div class="subtitle">Let Swoop help you find an investor for your business!</div>
        <div class="stepper-wrapper">
          <div class="stepper-item small">
            <div class="step-counter small"></div>
            <div class="step-name small">1. Answer a few questions</div>
          </div>
          <div class="stepper-item small">
            <div class="step-counter small"></div>
            <div class="step-name small">2. Upload your pitch deck</div>
          </div>
          <div class="stepper-item small inactive">
            <div class="step-counter small inactive"></div>
            <div class="step-name small">3. Swoop will review your application</div>
          </div>
        </div>
      </div>

      <div class="actions">
        <button id="go-to-equity" class="btn btn-primary btn-xs desktop-only" @click="goToEquityJourney()">Provide more information</button>
      </div>
    </div>
  </div>
  <EquityUnlocked v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import equityIcon from '@/assets/images/icons/equity-icon.svg?inline'
import phoneIcon from '@/assets/images/icons/icons_phone.svg?inline'
import peopleIcon from '@/assets/images/icons/icons_people.svg?inline'
import contactsIcon from '@/assets/images/icons/icons_contact.svg?inline'
import { authGetters } from '@/store/modules/auth/routines'
import EquityUnlocked from '@product/features/products/equity/equityUnlocked'

export default {
  name: 'EquityBanner',
  components: {
    equityIcon,
    phoneIcon,
    peopleIcon,
    contactsIcon,
    EquityUnlocked
  },
  methods: {
    goToEquityJourney() {
      this.$router.push({ name: 'equity-matches' })
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER
    }),
    equityUnlocked() {
      return this.user?.goalsCompleted?.some(form => form.type === 'Equity')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.equity-banner-locked {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;
  gap: 32px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  border-radius: 16px;

  .content {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2e9c8e;
      width: 64px;
      height: 64px;
      border-radius: 50%;

      .icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    font-style: normal;
    letter-spacing: -0.004em;
    gap: 4px;
    flex-grow: 2;

    @media only screen and (max-width: $breakpoint-sm) {
      gap: 8px;
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #003e52;
    }
    .subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #336575;
    }
  }
  .actions {
    align-self: center;
  }
}

/deep/.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  .stepper-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 124%;
    letter-spacing: -0.4px;
    color: #003e52;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &::before {
      position: absolute;
      content: '';
      border-bottom: 2px solid #2e9c8e;
      width: 100%;
      top: 20px;
      left: -5%;
      z-index: 2;
    }
    &::after {
      position: absolute;
      content: '';
      border-bottom: 2px solid #2e9c8e;
      width: 100%;
      top: 20px;
      left: 25%;
      z-index: 2;
    }
    &.small::after {
      top: 5px;
      left: 3%;
    }
    &.small::before {
      left: 3%;
      top: 5px;
    }
    &.inactive::before {
      border-bottom: 2px solid #dee8ec;
    }
    &.inactive::after {
      border-bottom: 2px solid #dee8ec;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::after {
      content: none;
    }
  }
  .step-counter {
    color: #ffffff;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #2e9c8e;
    margin-bottom: 10px;

    &.small {
      width: 10px;
      height: 10px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
  .step-name {
    margin-left: 15px;

    &.small {
      margin-left: 0;
      width: 80%;
    }
  }
}

</style>