<template>
  <div class="equity-banner-unlocked">
    <div class="first-box">
      <div class="content">
        Swoop's equity team will review your application and will be in contact with next steps. If they think you can progress to the next stage of your equity journey they will:
      </div>
      <div class="stepper-wrapper">
        <div class="stepper-item">
          <div class="step-counter"><phoneIcon /></div>
          <div class="step-name">1. Arrange a call to learn more about your business</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter"><peopleIcon /></div>
          <div class="step-name">2. Match you with potential investors</div>
        </div>
        <div class="stepper-item">
          <div class="step-counter"><contactsIcon /></div>
          <div class="step-name">3. Reach out to these investors and set up introductory calls</div>
        </div>
      </div>
    </div>
    <div class="second-box">
      <div class="title">When we call:</div>
      <div class="list">
        <div class="item">
          <div class="order">1</div>
          <div class="content">We'll ask some additional questions in order to help us understand your business and it's goals in more detail.</div>
        </div>
        <div class="item">
          <div class="order">2</div>
          <div class="content">We'll talk to you about the various options available to secure your funding.</div>
        </div>
        <div class="item">
          <div class="order">3</div>
          <div class="content">We'll ensure you leave the call feeling confident that your business needs are in safe hands with us.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import equityIcon from '@/assets/images/icons/equity-icon.svg?inline'
import phoneIcon from '@/assets/images/icons/icons_phone.svg?inline'
import peopleIcon from '@/assets/images/icons/icons_people.svg?inline'
import contactsIcon from '@/assets/images/icons/icons_contact.svg?inline'

export default {
  name:'equityUnlocked',
  components: {
    equityIcon,
    phoneIcon,
    peopleIcon,
    contactsIcon
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.equity-banner-unlocked {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media only screen and (max-width: $breakpoint-sm) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }
  .first-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 24px;
    border: 1px solid #d5ebe8;
    background: #ffffff;
    border-radius: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.004em;
    color: #546a7b;
    .stepper {
      width: 100%;
    }
  }

  .second-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 24px;
    background: #edfaf8;
    border: 1px solid #d5ebe8;
    border-radius: 12px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.004em;
      color: #003e52;
    }
    .list {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.35px;
      color: #003e52;
    }
  }
}

 .stepper-wrapper {
  display: flex;
  justify-content: space-between;
  .stepper-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 124%;
    letter-spacing: -0.4px;
    color: #003e52;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &::before {
      position: absolute;
      content: '';
      border-bottom: 2px solid #2e9c8e;
      width: 100%;
      top: 20px;
      left: -5%;
      z-index: 2;
    }
    &::after {
      position: absolute;
      content: '';
      border-bottom: 2px solid #2e9c8e;
      width: 100%;
      top: 20px;
      left: 25%;
      z-index: 2;
    }
    &.small::after {
      top: 5px;
      left: 5%;
    }
    &.small::before {
      left: 5%;
      top: 5px;
    }
    &.inactive::before {
      border-bottom: 2px solid #dee8ec;
    }
    &.inactive::after {
      border-bottom: 2px solid #dee8ec;
    }
    &:first-child::before {
      content: none;
    }
    &:last-child::after {
      content: none;
    }
  }
  .step-counter {
    color: #ffffff;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #2e9c8e;
    margin-bottom: 10px;

    &.small {
      width: 10px;
      height: 10px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
  .step-name {
    margin-left: 15px;

    &.small {
      margin-left: 0;
      width: 80%;
    }
  }
}
.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .item {
    display: grid;
    gap: 8px;
    grid-template-columns: 20px 1fr;
    .order {
      height: 20px;
      background-color: #2e9c8e;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-style: normal;
      font-weight: 400;
      border-radius: 20%;
    }
    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.35px;
    }
  }
}
</style>