<template>
  <div class="integrations-box" v-if="showLinkBankAccount || showLinkAccountancy">
    <div class="text">{{integrationsText}}</div>
    <div class="buttons">
      <button id="link-accounting-desktop" class="btn btn-primary btn-xs desktop-only" v-if="showLinkAccountancy" @click="goToIntegrationsPage()">Link your accounting software</button>
      <button id="link-bank-desktop" class="btn btn-primary btn-xs desktop-only" v-if="showLinkBankAccount" @click="goToIntegrationsPage()">Link your bank account</button>

      <button id="link-accounting-mobile" class="btn btn-primary btn-xs mobile-only" v-if="showLinkAccountancy" @click="goToIntegrationsPage()">Accounting software</button>
      <button id="link-bank-mobile" class="btn btn-primary btn-xs mobile-only" v-if="showLinkBankAccount" @click="goToIntegrationsPage()">Bank account</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntegrationsBanner',
  methods: {
    goToIntegrationsPage() {
      this.$router.push({ name: 'integrations' })
    }
  },
  computed: {
    showLinkBankAccount() {
      return this.$config.whitelabel.features.linkBankAccount
    },
    showLinkAccountancy() {
      return this.$config.whitelabel.features.linkAccountancy
    },
    integrationsText() {
      if (window.WHITE_LABEL_STYLES?.subdomain !== 'swoop') {
        return 'Securely link your financial data to get the most accurate results'
      }
      return 'Securely link your financial data with Swoop to get the most accurate results'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.mobile-only {
  @media only screen and (min-width: $breakpoint-sm) {
    display: none;
  }
}

.desktop-only {
  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }
}

.integrations-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 32px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(27, 103, 152, 0.15018), 0px 1px 3px rgba(27, 103, 152, 0.150131);
  border-radius: 100px;
  margin-top: 16px;

  .buttons > :nth-child(odd) {
    margin-right: 16px;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    border-radius: 0;
    padding: 16px;
    margin-top: 8px;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 8px;
    }

    .buttons > :nth-child(odd) {
      margin-right: 0;
    }
  }
}
</style>
