export const sortLoanFormsByMostRecentFilled = (filledForms, loanForms) => {
  // avoid any side effects
  const _loanForms = [...loanForms]
  const _filledForms = filledForms || []

  const getIndex = form => _filledForms.findIndex(filled => filled.formId === form.formId)

  _filledForms.sort((a, b) => (new Date(a.lastModifiedDate).getTime() > new Date(b.lastModifiedDate).getTime() ? -1 : 1))

  _loanForms.sort((formA, formB) => {
    const formAIndex = getIndex(formA)
    const formBIndex = getIndex(formB)
    const b = formBIndex !== -1 ? _filledForms.length - formBIndex : 0
    const a = formAIndex !== -1 ? _filledForms.length - formAIndex : 0

    return b - a
  })

  return _loanForms
}
