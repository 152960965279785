export default {
    methods: {
        convertToCamelCase(loan) {
         
            if (loan) {
                return loan
                    .split(/[^a-zA-Z0-9]/g)
                    .map((word, index) => {
                        if (index === 0) return word.toLowerCase()
                        return word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase()
                    })
                    .join('')
            }
            
        },

    },

}